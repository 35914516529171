// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent, useEffect } from "react";
import {
  Box, Button, debounce, FormLabel, Grid, Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DocumentTitle from "react-document-title";
import { FilterBuilder } from "@fas/cpa-cabinet-ui";
import en from "dayjs/locale/en-gb";
import { GetApp } from "@mui/icons-material";
import type { Filters, Sorting } from "@fas/cpa-state-manager/redux/actions/table";
import type { Column } from "../TableComponent/types/TableComponent.types";
import { MAIN_TABLE } from "../../helpers/constants";
import { TableComponent, TablePagination } from "../TableComponent";
import type { ItemData, TotalData } from "../../services/mainReportApi";
import {
  mapOptions, getMinDate, presets, renderOption, renderTags,
} from "../../helpers/generators";
import {
  DateCellComponent,
  DialogComponent,
  FooterComponentMainReport,
} from "./DateRangePickerComponent";
import NoAvailableData from "../NoAvailableData";
import useDictionary from "../../hoocks/useDictionary";
import type { Field } from "../../reducers/transactionReport/reducer";
import { getColumns } from "./getColumns";
import { dynamicFieldsOptions, dynamicFieldsOptionsMetrics } from "../../reducers/mainReport/reducer";
import CheckBoxList from "./CheckBoxList";

const FilterWrapper: StatelessFunctionalComponent<*> = ({ children }) => (
  <Box display="inline-block" pr={1} pb={1}>{children}</Box>
);

type Props = {|
  data: ItemData[],
  totalData: TotalData,
  page: number,
  total: number,
  pageSize: number,
  filters: Filters,
  sorting: Sorting,
  loading: boolean,
  onChangeTablePage: (string, number) => mixed,
  onChangeTableFilters: (string, Filters) => mixed,
  onChangeTableSorting: (string, Sorting) => mixed,
  onChangeTablePageSize: (string, number) => mixed,
  onGetData: () => mixed,
  onDownloadReport: () => mixed,
  fields: Field<ItemData>[],
|}

export const useStyles = makeStyles((theme) => ({
  input: {
    width: "180px",
  },
  textField: {
    width: "180px",
  },
  multiSelect: {
    width: "253px",
  },
  dateRange: {
    paddingTop: "0px",
    width: "253px",
    "& textarea": {
      textAlign: "center",
    },
    "& .MuiButtonBase-root": {
      padding: "2px",
    },
  },
  label: {},
  download: {
    width: "170px",
    "& > span": {
      ...theme.typography.gradient,
      display: "inline-block",
    },
  },
  apply: {
    width: "170px",
  },
  endIcon: {
    "& > svg": {
      fill: "url(#selected)",
    },
    marginBottom: "-5px",
  },
  headerCell: {
    verticalAlign: "top",
    color: theme.palette.primary.main,
    "& > span": {
      ...theme.typography.gradient,
      display: "inline-block",
    },
  },
  cell: {
    textAlign: "center",
    paddingBottom: theme.spacing(1),
    wordBreak: "break-word",
  },
}));

const MainReport: StatelessFunctionalComponent<Props> = ({
  data,
  totalData,
  page,
  total,
  pageSize,
  sorting,
  filters,
  loading,
  onChangeTablePage,
  onChangeTableSorting,
  onChangeTableFilters,
  onChangeTablePageSize,
  onGetData,
  onDownloadReport,
  fields,
}: Props) => {
  const classes = useStyles();

  useEffect(() => {
    if (data.length === 0) {
      onGetData();
    }
  }, []);
  const { list: offersDropdown, loading: offersDropDownLoading } = useDictionary("offerMain");
  const { list: subIdDropdown, loading: subIdDropdownLoading } = useDictionary("p1Main");
  const { list: countryDropdown, loading: countryDropdownLoading } = useDictionary("country");
  const { list: platformDropdown, loading: platformDropdownLoading } = useDictionary("platform");
  const { list: osDropdown, loading: osDropdownLoading } = useDictionary("osMain");

  const {
    columns,
    totalColumns,
  }: { columns: Array<Column<ItemData>>, totalColumns: Array<Column<TotalData>> } = getColumns(fields);

  const isShowData: boolean = data.length > 0 && !loading;
  const isShowNoDataMessage: boolean = data.length === 0 && !loading;

  function handleRangeChangeDateRangePicker({ startDate, endDate }) {
    onChangeTableFilters(MAIN_TABLE, { dateFrom: startDate, dateTo: endDate });
  }

  const debounceGetData = debounce(onGetData, 1000);

  const filterItems = [
    {
      type: "multiSelect",
      filterKey: "offer_name",
      filterProps: {
        renderTags,
        renderOption,
        variant: "standard",
        label: "Offers",
        className: classes.multiSelect,
        classes: { formLabel: classes.label },
        disabled: loading || offersDropDownLoading,
        "data-testid": "filter-offer_name",
        options: [
          ...offersDropdown,
        ].map(mapOptions),
      },
    },
    {
      type: "multiSelect",
      filterKey: "p1",
      filterProps: {
        variant: "standard",
        renderTags,
        renderOption,
        label: "SubId",
        className: classes.multiSelect,
        classes: { formLabel: classes.label },
        disabled: loading || subIdDropdownLoading,
        "data-testid": "filter-p1",
        options: [
          ...subIdDropdown,
        ].map(mapOptions),
      },
    },
    {
      type: "multiSelect",
      filterKey: "country",
      filterProps: {
        renderTags,
        renderOption,
        variant: "standard",
        label: "Country",
        className: classes.multiSelect,
        classes: { formLabel: classes.label },
        disabled: loading || countryDropdownLoading,
        "data-testid": "filter-country",
        options: [
          ...countryDropdown,
        ].map(mapOptions),
      },
    },
    {
      type: "select",
      filterKey: "platform",
      filterProps: {
        variant: "standard",
        label: "Device",
        className: classes.input,
        classes: { formLabel: classes.label },
        disabled: loading || platformDropdownLoading,
        "data-testid": "filter-platform",
        options: [
          ...platformDropdown,
        ].map(mapOptions),
      },
    },
    {
      type: "select",
      filterKey: "smartlink_type",
      filterProps: {
        variant: "standard",
        label: "Smartlink type",
        className: classes.multiSelect,
        classes: { formLabel: classes.label },
        disabled: loading,
        "data-testid": "filter-smartlink_type",
        options: [
          { label: "Only Smartlink offers", value: "smartlink" },
          { label: "Only Direct offers", value: "direct" },
        ].map(mapOptions),
      },
    },
    {
      type: "multiSelect",
      filterKey: "device_os",
      filterProps: {
        renderTags,
        renderOption,
        variant: "standard",
        label: "OS",
        className: classes.multiSelect,
        classes: { formLabel: classes.label },
        disabled: loading || osDropdownLoading,
        "data-testid": "filter-os",
        options: [
          ...osDropdown,
        ].map(mapOptions),
      },
    },
    {
      type: "dateRangePicker",
      filterKey: "dateRangePicker",
      filterProps: {
        readOnly: false,
        minDate: getMinDate(),
        variant: "standard",
        DateCellComponent,
        DialogComponent,
        FooterComponent: FooterComponentMainReport,
        disabled: loading,
        classes: { input: { root: classes.dateRange }, formLabel: classes.label },
        label: "Date range",
        startDate: filters.dateFrom,
        endDate: filters.dateTo,
        onChangeDate: handleRangeChangeDateRangePicker,
        onChangePreset: handleRangeChangeDateRangePicker,
        presets,
        locale: en,
        "data-testid": "filter-date-range-picker",
      },
    },
  ];

  return (
    <DocumentTitle title="Main Report">
      <Grid container>
        <Grid item xs={12}>
          <Box width={1} pb={2} display="flex" justifyContent="space-between">
            <Typography variant="h2">Main Report</Typography>
            <Box>
              <Button
                className={classes.download}
                classes={{
                  endIcon: classes.endIcon,
                }}
                variant="contained"
                color="secondary"
                name="download"
                disabled={loading || isShowNoDataMessage}
                endIcon={<GetApp />}
                onClick={onDownloadReport}
              >
                <span>
                  Export to CSV
                </span>
              </Button>
            </Box>
          </Box>
          <Box display="flex">
            <Box flexGrow={1} display="flex" flexDirection="column">
              <Box>
                <FilterBuilder
                  data-testid="filters"
                  WrapperItemComponent={FilterWrapper}
                  // $FlowFixMe
                  filters={filters}
                  // $FlowFixMe
                  onFiltersChange={(newFilters: Filters) => onChangeTableFilters(MAIN_TABLE, newFilters)}
                  items={filterItems}
                />
              </Box>
              <FormLabel focused={false}>Show columns</FormLabel>
              <Box pl={1}>
                <FilterWrapper>
                  {/* // $FlowFixMe */}
                  <CheckBoxList
                    data-testid="filter-dynamicFields"
                    label="Data"
                    // $FlowFixMe
                    options={dynamicFieldsOptions.map(mapOptions)}
                    filterKey="dynamicFields"
                    // $FlowFixMe
                    value={filters.dynamicFields}
                    onChange={(value) => onChangeTableFilters(MAIN_TABLE, { ...filters, ...value })}
                  />
                </FilterWrapper>
                <FilterWrapper>
                  {/* // $FlowFixMe */}
                  <CheckBoxList
                    data-testid="filter-dynamicFields-metric"
                    label="Metrics"
                    // $FlowFixMe
                    options={dynamicFieldsOptionsMetrics.map(mapOptions)}
                    filterKey="dynamicFields"
                    // $FlowFixMe
                    value={filters.dynamicFields}
                    onChange={(value) => onChangeTableFilters(MAIN_TABLE, { ...filters, ...value })}
                  />
                </FilterWrapper>
              </Box>
            </Box>
            <Box alignSelf="flex-start" display="flex" pt={2} flexShrink={0}>
              <Box>
                <Button
                  className={classes.apply}
                  variant="contained"
                  color="primary"
                  name="applyFilters"
                  disabled={loading}
                  onClick={onGetData}
                >
                  Apply filters
                </Button>
              </Box>
            </Box>
          </Box>
          { (loading || isShowNoDataMessage) && (
            <Box pt={30}>
              <NoAvailableData loading={loading} />
            </Box>
          )}
          { isShowData && (
            <>
              <TableComponent
                classes={classes}
                data={data}
                sorting={sorting}
                columns={columns}
                onChangeSorting={(newSorting: Sorting) => {
                  onChangeTableSorting(MAIN_TABLE, newSorting);
                  debounceGetData();
                }}
                totalData={totalData}
                totalColumns={totalColumns}
              />
              <TablePagination
                data-testid="pagination"
                page={page}
                pageSize={pageSize}
                count={total}
                onChangePage={(newPage: number) => {
                  onChangeTablePage(MAIN_TABLE, newPage);
                  onGetData();
                }}
                onChangePageSize={(newPageSize: number) => {
                  onChangeTablePage(MAIN_TABLE, 1);
                  onChangeTablePageSize(MAIN_TABLE, newPageSize);
                  onGetData();
                }}
              />
            </>
          )}
        </Grid>
      </Grid>
    </DocumentTitle>
  );
};

export default MainReport;
