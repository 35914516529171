// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent, useCallback, useEffect } from "react";
import {
  Box, Button, debounce, Grid, Typography,
} from "@mui/material";
import DocumentTitle from "react-document-title";
import { FilterBuilder } from "@fas/cpa-cabinet-ui";
import en from "dayjs/locale/en-gb";
import { GetApp } from "@mui/icons-material";
import type { Filters, Sorting } from "@fas/cpa-state-manager/redux/actions/table";
import type { Column } from "../TableComponent/types/TableComponent.types";
import { TRANSACTION_TABLE } from "../../helpers/constants";
import { TableComponent, TablePagination } from "../TableComponent";
import type { ItemData, TotalData } from "../../services/transactionReportApi";
import {
  mapOptions, getMinDate, presets, renderOption, renderTags,
} from "../../helpers/generators";
import { DateCellComponent, DialogComponent, FooterComponent } from "../MainReport/DateRangePickerComponent";
import NoAvailableData from "../NoAvailableData";
import useDictionary from "../../hoocks/useDictionary";
import { getColumns } from "./getColumns";
import type { Field } from "../../reducers/transactionReport/reducer";
import { dynamicFieldsOptions } from "../../reducers/transactionReport/reducer";
import CheckBoxList from "../MainReport/CheckBoxList";
import { useStyles } from "../MainReport/MainReport";

const FilterWrapper: StatelessFunctionalComponent<*> = ({ children }) => (
  <Box display="inline-block" pr={1} pb={1}>{children}</Box>
);

type Props = {|
  data: ItemData[],
  totalData: TotalData,
  page: number,
  total: number,
  pageSize: number,
  filters: Filters,
  sorting: Sorting,
  loading: boolean,
  onChangeTablePage: (string, number) => mixed,
  onChangeTableFilters: (string, Filters) => mixed,
  onChangeTableSorting: (string, Sorting) => mixed,
  onChangeTablePageSize: (string, number) => mixed,
  onGetData: () => mixed,
  onDownloadReport: () => mixed,
  fields: Field<ItemData>[],
|}

/* eslint-disable complexity */
const TransactionReport: StatelessFunctionalComponent<Props> = ({
  data,
  totalData,
  page,
  total,
  pageSize,
  sorting,
  filters,
  loading,
  onChangeTablePage,
  onChangeTableSorting,
  onChangeTableFilters,
  onChangeTablePageSize,
  onGetData,
  onDownloadReport,
  fields,
}: Props) => {
  const classes = useStyles();

  useEffect(() => {
    if (data.length === 0) {
      onGetData();
    }
  }, []);
  const { list: offersDropdown, loading: offersDropDownLoading } = useDictionary("offerTransaction");
  const { list: subIdDropdown, loading: subIdDropdownLoading } = useDictionary("p1Transaction");
  const { list: countryDropdown, loading: countryDropdownLoading } = useDictionary("country");
  const { list: platformDropdown, loading: platformDropdownLoading } = useDictionary("platform");
  const { list: osDropdown, loading: osDropdownLoading } = useDictionary("osTransaction");

  const debouncedGetData = useCallback(debounce(onGetData, 1000), [onGetData]);

  const {
    columns,
    totalColumns,
  }: { columns: Array<Column<ItemData>>, totalColumns: Array<Column<TotalData>> } = getColumns(fields);

  const isShowData: boolean = data.length > 0 && !loading;
  const isShowNoDataMessage: boolean = data.length === 0 && !loading;

  function handleRangeChangeDateRangePicker({ startDate, endDate }) {
    onChangeTableFilters(TRANSACTION_TABLE, { dateFrom: startDate, dateTo: endDate });
  }

  const filterItems = [
    {
      type: "multiSelect",
      filterKey: "offer_name",
      filterProps: {
        renderTags,
        renderOption,
        variant: "standard",
        label: "Offers",
        className: classes.multiSelect,
        classes: { formLabel: classes.label },
        disabled: loading || offersDropDownLoading,
        "data-testid": "filter-offer_name",
        options: [
          ...offersDropdown,
        ].map(mapOptions),
      },
    },
    {
      type: "multiSelect",
      filterKey: "p1",
      filterProps: {
        renderTags,
        renderOption,
        variant: "standard",
        label: "SubId",
        className: classes.multiSelect,
        classes: { formLabel: classes.label },
        disabled: loading || subIdDropdownLoading,
        "data-testid": "filter-p1",
        options: [
          ...subIdDropdown,
        ].map(mapOptions),
      },
    },
    {
      type: "multiSelect",
      filterKey: "country",
      filterProps: {
        renderTags,
        renderOption,
        variant: "standard",
        label: "Country",
        className: classes.multiSelect,
        classes: { formLabel: classes.label },
        disabled: loading || countryDropdownLoading,
        "data-testid": "filter-country",
        options: [
          ...countryDropdown,
        ].map(mapOptions),
      },
    },
    {
      type: "select",
      filterKey: "platform",
      filterProps: {
        variant: "standard",
        label: "Device",
        className: classes.input,
        classes: { formLabel: classes.label },
        disabled: loading || platformDropdownLoading,
        "data-testid": "filter-platform",
        options: [
          ...platformDropdown,
        ].map(mapOptions),
      },
    },
    {
      type: "select",
      filterKey: "smartlink_type",
      filterProps: {
        variant: "standard",
        label: "Smartlink type",
        className: classes.multiSelect,
        classes: { formLabel: classes.label },
        disabled: loading,
        "data-testid": "filter-smartlink_type",
        options: [
          { label: "Only Smartlink offers", value: "smartlink" },
          { label: "Only Direct offers", value: "direct" },
        ].map(mapOptions),
      },
    },
    {
      type: "multiSelect",
      filterKey: "device_os",
      filterProps: {
        renderTags,
        renderOption,
        variant: "standard",
        label: "OS",
        className: classes.multiSelect,
        classes: { formLabel: classes.label },
        disabled: loading || osDropdownLoading,
        "data-testid": "filter-os",
        options: [
          ...osDropdown,
        ].map(mapOptions),
      },
    },
    {
      type: "dateRangePicker",
      filterKey: "dateRangePicker",
      filterProps: {
        readOnly: false,
        minDate: getMinDate(),
        variant: "standard",
        DateCellComponent,
        DialogComponent,
        FooterComponent,
        disabled: loading,
        classes: { input: { root: classes.dateRange }, formLabel: classes.label },
        label: "Date range",
        startDate: filters.dateFrom,
        endDate: filters.dateTo,
        onChangeDate: handleRangeChangeDateRangePicker,
        onChangePreset: handleRangeChangeDateRangePicker,
        presets,
        locale: en,
        "data-testid": "filter-date-range-picker",
      },
    },
    {
      type: "select",
      filterKey: "is_declined",
      filterProps: {
        variant: "standard",
        label: "Status",
        className: classes.input,
        classes: { formLabel: classes.label },
        disabled: loading,
        "data-testid": "filter-is_declined",
        options: [
          { value: false, label: "Approved" },
          { value: true, label: "Declined" },
          // $FlowFixMe
        ].map(mapOptions),
      },
    },
    {
      type: "textField",
      filterKey: "partner_click",
      filterProps: {
        variant: "standard",
        label: "ClickID",
        className: classes.textField,
        classes: { formLabel: classes.label },
        disabled: loading || osDropdownLoading,
        "data-testid": "filter-partner_click",
      },
    },
  ];

  const fieldsShowColumns = [
    {
      type: "checkBoxList",
      filterKey: "dynamicFields",
      filterProps: {
        variant: "standard",
        label: "Show columns",
        className: classes.multiSelect,
        classes: { formLabel: classes.label },
        disabled: loading,
        "data-testid": "filter-dynamicFields",
        options: dynamicFieldsOptions.map(mapOptions),
      },
    },
  ];

  return (
    <DocumentTitle title="Transaction Report">
      <Grid container>
        <Grid item xs={12}>
          <Box width={1} pb={2} display="flex" justifyContent="space-between">
            <Typography variant="h2">Transaction Report</Typography>
            <Box>
              <Button
                className={classes.download}
                classes={{
                  endIcon: classes.endIcon,
                }}
                variant="contained"
                color="secondary"
                name="download"
                disabled={loading || isShowNoDataMessage}
                endIcon={<GetApp />}
                onClick={onDownloadReport}
              >
                <span>
                  Export to CSV
                </span>
              </Button>
            </Box>
          </Box>
          <Box display="flex">
            <Box flexGrow={1} display="flex" flexDirection="column">
              <Box>
                <FilterBuilder
                  data-testid="filters"
                  WrapperItemComponent={FilterWrapper}
                  // $FlowFixMe
                  filters={filters}
                  onFiltersChange={(newFilters: Filters) => onChangeTableFilters(TRANSACTION_TABLE, newFilters)}
                  items={filterItems}
                />
              </Box>
              <FilterBuilder
                data-testid="filters-dynamic-fields"
                WrapperItemComponent={FilterWrapper}
                // $FlowFixMe
                filters={filters}
                // $FlowFixMe
                onFiltersChange={(newFilters: Filters) => onChangeTableFilters(TRANSACTION_TABLE, newFilters)}
                // $FlowFixMe
                items={fieldsShowColumns}
                componentMapping={{
                  // $FlowFixMe
                  checkBoxList: CheckBoxList,
                }}
              />
            </Box>
            <Box alignSelf="flex-start" display="flex" pt={2} flexShrink={0}>
              <Box>
                <Button
                  className={classes.apply}
                  variant="contained"
                  color="primary"
                  name="applyFilters"
                  disabled={loading}
                  onClick={onGetData}
                >
                  Apply filters
                </Button>
              </Box>
            </Box>
          </Box>
          { (loading || isShowNoDataMessage) && (
            <Box pt={30}>
              <NoAvailableData loading={loading} />
            </Box>
          )}
          { isShowData && (
            <>
              <TableComponent
                classes={classes}
                data={data}
                sorting={sorting}
                columns={columns}
                onChangeSorting={(newSorting: Sorting) => {
                  onChangeTableSorting(TRANSACTION_TABLE, newSorting);
                  debouncedGetData();
                }}
                totalData={totalData}
                totalColumns={totalColumns}
              />
              <TablePagination
                data-testid="pagination"
                page={page}
                pageSize={pageSize}
                count={total}
                onChangePage={(newPage: number) => {
                  onChangeTablePage(TRANSACTION_TABLE, newPage);
                  onGetData();
                }}
                onChangePageSize={(newPageSize: number) => {
                  onChangeTablePage(TRANSACTION_TABLE, 1);
                  onChangeTablePageSize(TRANSACTION_TABLE, newPageSize);
                  onGetData();
                }}
              />
            </>
          )}
        </Grid>
      </Grid>
    </DocumentTitle>
  );
};

export default TransactionReport;
