// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent, useCallback, useEffect } from "react";
import {
  Box, Button, debounce, Grid, Typography,
} from "@mui/material";
import DocumentTitle from "react-document-title";
import { FilterBuilder } from "@fas/cpa-cabinet-ui";
import en from "dayjs/locale/en-gb";
import type { Sorting, Filters } from "@fas/cpa-state-manager/redux/actions/table";
import type { Column } from "../TableComponent/types/TableComponent.types";
import { PAYOUT_HISTORY_TABLE } from "../../helpers/constants";
import { TableComponent, TablePagination } from "../TableComponent";
import type { ItemData } from "../../services/payoutHistoryReportApi";
import { getMinDate, presets } from "../../helpers/generators";
import { DateCellComponent, DialogComponent, FooterComponent } from "../MainReport/DateRangePickerComponent";
import NoAvailableData from "../NoAvailableData";
import { useStyles } from "../MainReport/MainReport";

const FilterWrapper: StatelessFunctionalComponent<*> = ({ children }) => (
  <Box display="inline-block" pr={1} pb={1}>{children}</Box>
);

type Props = {|
  data: ItemData[],
  page: number,
  total: number,
  pageSize: number,
  filters: Filters,
  sorting: Sorting,
  loading: boolean,
  onChangeTablePage: (string, number) => mixed,
  onChangeTableFilters: (string, Filters) => mixed,
  onChangeTableSorting: (string, Sorting) => mixed,
  onChangeTablePageSize: (string, number) => mixed,
  onGetData: () => mixed,
|}

const PayoutHistoryReport: StatelessFunctionalComponent<Props> = ({
  data,
  page,
  total,
  pageSize,
  sorting,
  filters,
  loading,
  onChangeTablePage,
  onChangeTableSorting,
  onChangeTableFilters,
  onChangeTablePageSize,
  onGetData,
}: Props) => {
  const classes = useStyles();

  useEffect(() => {
    if (data.length === 0) {
      onGetData();
    }
  }, []);
  const debouncedGetData = useCallback(debounce(onGetData, 1000), [onGetData]);

  const columns: Array<Column<ItemData>> = [
    {
      label: "Created",
      field: "created",
      key: "created",
      sortable: true,
    },
    {
      label: "Period from",
      field: "dateFrom",
      key: "dateFrom",
      sortable: true,
    },
    {
      label: "Period to",
      field: "dateTo",
      key: "dateTo",
      sortable: true,
    },
    {
      label: "Paid total",
      field: "payout",
      key: "payout",
      sortable: true,
      render: ({ row }) => `${row.currency} ${Number(row.payout).toFixed(2)}`,
    },
    {
      label: "Paid on",
      field: "paidOn",
      key: "paidOn",
      sortable: true,
    },
    {
      label: "Payment method",
      field: "paymentMethod",
      key: "paymentMethod",
      sortable: true,
    },
  ];

  const isShowData: boolean = data.length > 0 && !loading;
  const isShowNoDataMessage: boolean = data.length === 0 && !loading;

  function handleRangeChangeDateRangePicker({ startDate, endDate }) {
    onChangeTableFilters(PAYOUT_HISTORY_TABLE, { dateFrom: startDate, dateTo: endDate });
  }

  return (
    <DocumentTitle title="Payout History">
      <Grid container>
        <Grid item xs={12}>
          <Box width={1} pb={2} display="flex" justifyContent="space-between">
            <Typography variant="h2">Payout History</Typography>
          </Box>
          <Box display="flex">
            <Box flexGrow={1}>
              <FilterBuilder
                data-testid="filters"
                WrapperItemComponent={FilterWrapper}
                // $FlowFixMe
                filters={filters}
                onFiltersChange={(newFilters: Filters) => onChangeTableFilters(PAYOUT_HISTORY_TABLE, newFilters)}
                items={[
                  {
                    type: "dateRangePicker",
                    filterKey: "dateRangePicker",
                    filterProps: {
                      readOnly: false,
                      minDate: getMinDate(),
                      variant: "standard",
                      DateCellComponent,
                      DialogComponent,
                      FooterComponent,
                      disabled: loading,
                      classes: { input: { root: classes.dateRange }, formLabel: classes.label },
                      label: "Date range",
                      startDate: filters.dateFrom,
                      endDate: filters.dateTo,
                      onChangeDate: handleRangeChangeDateRangePicker,
                      onChangePreset: handleRangeChangeDateRangePicker,
                      presets,
                      locale: en,
                      "data-testid": "filter-date-range-picker",
                    },
                  },
                ]}
              />
            </Box>
            <Box alignSelf="flex-start" display="flex" pt={2} flexShrink={0}>
              <Box>
                <Button
                  className={classes.apply}
                  variant="contained"
                  color="primary"
                  name="applyFilters"
                  disabled={loading}
                  onClick={onGetData}
                >
                  Apply filters
                </Button>
              </Box>
            </Box>
          </Box>
          { (loading || isShowNoDataMessage) && (
            <Box pt={30}>
              <NoAvailableData loading={loading} />
            </Box>
          )}
          { isShowData && (
            <>
              <TableComponent
                classes={classes}
                data={data}
                sorting={sorting}
                columns={columns}
                onChangeSorting={(newSorting: Sorting) => {
                  onChangeTableSorting(PAYOUT_HISTORY_TABLE, newSorting);
                  debouncedGetData();
                }}
              />
              <TablePagination
                data-testid="pagination"
                page={page}
                pageSize={pageSize}
                count={total}
                onChangePage={(newPage: number) => {
                  onChangeTablePage(PAYOUT_HISTORY_TABLE, newPage);
                  onGetData();
                }}
                onChangePageSize={(newPageSize: number) => {
                  onChangeTablePage(PAYOUT_HISTORY_TABLE, 1);
                  onChangeTablePageSize(PAYOUT_HISTORY_TABLE, newPageSize);
                  onGetData();
                }}
              />
            </>
          )}
        </Grid>
      </Grid>
    </DocumentTitle>
  );
};

export default PayoutHistoryReport;
